import Home1 from "./Home/Home1";
import Home2 from "./Home/Home2";
import Home3 from "./Home/Home3";
import Home4 from "./Home/Home4";
import Home5 from "./Home/Home5";
import Home6 from "./Home/Home6";
import Home7 from "./Home/Home7";
import Home8 from "./Home/Home8";
import Home9 from "./Home/Home9";

export default function Home() {
  return (
    <main className="flex-col ">
      <Home1 />
      <Home2 />
      <Home3 />
      <Home4 />
      <Home5 />
      <Home6 />
      <Home7 />
      <Home8 />
      <Home9 />
    </main>
  );
}
